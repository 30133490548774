import { ErrorBoundary } from '@sentry/nextjs';
import { useEffect } from 'react';
import SignIn from '@/components/features/SignIn';
import ErrorFallback from '@/components/ui/ErrorFallback';
import Layout from '@/components/features/Layout';
import Head from 'next/head';
import { useBrandData } from 'src/hooks/useBrandData';
import { useRouter } from 'next/router';

const SignInPage = () => {
  const router = useRouter();

  useEffect(() => {
    if (localStorage.getItem('access')) {
      router.push('/');
    }
  }, [router]);

  const { appName } = useBrandData();

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Head>
        <title>{`Sign in | ${appName}`}</title>
      </Head>
      <SignIn />
    </ErrorBoundary>
  );
};

SignInPage.getLayout = (page: any) => (
  <Layout menuHidden isPublic>
    <main>{page}</main>
  </Layout>
);
export default SignInPage;

import React from 'react';
import BlockTitle from '../ui/BlockTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { makeLogger } from '@/utils/makeLogger';
import { useRouter } from 'next/router';
import { useAuth } from 'src/hooks/useAuth';
import Tile from '@/components/ui/Tile';
import { useBrandData } from 'src/hooks/useBrandData';
import TextInput from '@/components/ui/TextInput';
import Button from '@/components/ui/Button';
import toast from '@/utils/toast';
import Link from 'next/link';

type LoginContent = {
  username: string;
  password: string;
};
const log = makeLogger('Login');

const SignIn = () => {
  const { baseUrl, appName, dealsUrl } = useBrandData();
  const { login } = useAuth();
  const router = useRouter();
  const schema = yup.object().shape({
    username: yup
      .string()
      .required('Username is required')
      .email()
      .min(6)
      .max(255),
    password: yup.string().required('Password is required').min(4),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginContent>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: LoginContent) => {
    try {
      await login(data);
      log('redirect');
      router.push('/');
    } catch (error: any) {
      log('Login submission error', error);
      if (error.message === 'CREDENTIALS') {
        toast.error('Username or password is incorrect');
      } else {
        toast.error("We're having trouble connecting. Please try again");
      }
    }
  };

  return (
    <Tile>
      <BlockTitle text="Sign in" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Email"
          type="email"
          autoComplete="email"
          errorMessage={errors?.username?.message}
          {...register('username')}
        />
        <TextInput
          label="Password"
          type="password"
          autoComplete="current-password"
          className="mt-4"
          errorMessage={errors?.password?.message}
          {...register('password')}
        />

        <div className="flex flex-col mt-4">
          <Button className="mt-4 w-full" loading={isSubmitting} type="submit">
            Sign in
          </Button>
          <Button
            type="button"
            className="w-full mt-4 "
            variant="secondary"
            onClick={() => router.push(dealsUrl)}
          >
            {`New to ${appName}? Sign up`}
          </Button>
        </div>
        <div className="mt-4 text-right">
          <Link
            className="text-purple hover:underline hover:text-darkPurple"
            href={`${baseUrl}/reset/recover/`}
          >
            Forgot password?
          </Link>
        </div>
      </form>
    </Tile>
  );
};

export default SignIn;
